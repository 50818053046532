export class JobContractTypes {
	static readonly record: Record<string, string> = {
		["agr"]: "Agriculteur",
		["art"]: "Artisan",
		["cmd"]: "Cadre moyen CDD",
		["csd"]: "Cadre supérieur CDD",
		["cmo"]: "Cadre moyen CDI",
		["csu"]: "Cadre supérieur CDI",
		["cho"]: "Chômeur, demandeur d'emploi",
		["ins"]: "Contrat d'insertion",
		["emd"]: "Employé CDD",
		["emp"]: "Employé CDI",
		["emn"]: "Employé cne",
		["ens"]: "Enseignant",
		["etu"]: "Etudiant, éleve, stagiaire",
		["fon"]: "Fonctionnaire",
		["han"]: "Handicapé, pensionné",
		["inc"]: "Inconnu",
		["lma"]: "Longue maladie",
		["mid"]: "Militaire, appelé CDD",
		["mil"]: "Militaire CDI",
		["oud"]: "Ouvrier CDD",
		["ouv"]: "Ouvrier CDI",
		["lib"]: "Profession libérale",
		["ret"]: "Retraité, pre-retraité",
		["san"]: "Sans profession",
		["sco"]: "Scolaire",
	};
}
