import { Injectable, inject } from "@angular/core";
import { environment } from "@e-tenant-hub/environments";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import { BaseApiResponse, CookiesAcceptance } from "../types";

@Injectable({
	providedIn: "root",
})
export class CookiesService {
	private readonly httpClient = inject(RentalsHttpClient);
	private readonly cookieService = inject(CookieService);

	private disableGa: string = "ga-disable-" + environment.googleAnalyticsCode;

	saveCookieAcceptance(request: CookiesAcceptance): Observable<BaseApiResponse> {
		return this.httpClient.post<BaseApiResponse>(`/api/cookie/send-cookie-acceptance`, request);
	}

	refuseCookieGa(expirationDate: number) {
		/*set domain use by GA */
		this.cookieService.deleteAll("/", ".est-metropole-habitat.fr");
		this.cookieService.deleteAll("/", "localhost");
		if (environment.googleAnalyticsCode) {
			this.cookieService.set(this.disableGa, "true", expirationDate);
		}
	}

	acceptCookieGa() {
		if (this.cookieService.check(this.disableGa)) {
			this.cookieService.delete(this.disableGa);
		}
	}
}
