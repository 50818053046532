import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import { BaseApiResponse } from "../types";

@Injectable({
	providedIn: "root",
})
export class FilesService {
	private readonly httpClient = inject(RentalsHttpClient);

	getOnlinePaymentNotice(rentalId: string, fileName: string): Observable<Blob> {
		return this.httpClient.downloadFile(
			`/api/rental-balances/${rentalId}/last-payment-notice/download/${fileName}`
		);
	}

	getSEPAForm(): Observable<Blob> {
		return this.httpClient.downloadLocalFile(`assets/documents/emh_mandat_de_prelevement_sepa.pdf`);
	}

	getBedbugsGuideUrl(): Observable<BaseApiResponse> {
		return this.httpClient.get<BaseApiResponse>(`/api/app/bedbugs-guide-url`);
	}

	getMaintenanceGuideUrl(): Observable<BaseApiResponse> {
		return this.httpClient.get<BaseApiResponse>(`/api/app/maintenance-guide-url`);
	}

	getRentalCostsGuideUrl(): Observable<BaseApiResponse> {
		return this.httpClient.get<BaseApiResponse>(`/api/app/rental-costs-guide-url`);
	}

	getRentalRepairGuideUrl(): Observable<BaseApiResponse> {
		return this.httpClient.get<BaseApiResponse>(`/api/app/rental-repair-guide-url`);
	}
}
