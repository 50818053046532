export class InsuranceStatusType {
	static readonly expired: string = "expired";
	static readonly upToDate: string = "up-to-date";
	static readonly processing: string = "processing";
	static readonly nearlyExpired: string = "nearly-expired";

	static readonly record: Record<string, string> = {
		[InsuranceStatusType.expired]: "Expirée",
		[InsuranceStatusType.upToDate]: "À jour",
		[InsuranceStatusType.nearlyExpired]: "Bientôt expirée",
	};

	static readonly iconRecord: Record<string, string> = {
		[InsuranceStatusType.expired]: "warning",
		[InsuranceStatusType.upToDate]: "check_circle",
		[InsuranceStatusType.nearlyExpired]: "warning",
	};
}
