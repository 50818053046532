import { PreciseDate } from "@e-tenant-hub/shared/utils";

export interface GetAllSolicitationsResponse {
	isNewSolicitationRequestDisabled: boolean;
	items: SolicitationResponseItem[];
}

export interface SolicitationResponseItem {
	id: string;
	cancellationDate: PreciseDate | null;
	canInteract: boolean;
	category: string;
	closingDate: string;
	companyMail: string;
	companyMobilePhone: string;
	companyName: string;
	companyOfficePhone: string;
	creationDate: PreciseDate | null;
	isClosureRequestAllowed: boolean;
	isCommentAllowed: boolean;
	isNew: boolean;
	isPublic: boolean;
	isReminderAllowed: boolean;
	isRequested: boolean;
	lastEditionDate: PreciseDate | null;
	mainComment: string;
	modificationDate: PreciseDate | null;
	rentalId: string;
	status: string;
	title: string;

	solicitationSteps: SolicitationStepResponseItem[] | null;
}

export interface SolicitationStepResponseItem {
	id: string;
	comment: string;
	commentType: string;
	creationDate: PreciseDate | null;
	information: string;
	isRequestedByCurrentRentalContract: boolean;
	solicitationId: string;
}

export interface NbNewSolicitationsResponseItem {
	nbNewSolicitations: number;
}

export interface SolicitationCreationRequest {
	description: string;
	isPublic: boolean;
	rentalId: string;
	thirdPartyId: string;
	title: string;
}

export interface SolicitationCreationResponse {
	requestId: string;
}

export interface SolicitationStepRequest {
	comment: string;
	rentalId: string;
	thirdParty: string;
	type: string;
}

export class SolicitationFilter {
	id: string;
	name!: string;
	category!: string;
	value?: string | number | PreciseDate[] | boolean;
	selected!: boolean;

	constructor(init?: Partial<SolicitationFilter>) {
		this.id = "_" + Math.random().toString(36).slice(2, 9);
		Object.assign(this, init);
	}
}
