export class FamilySituationTypes {
	static readonly record: Record<string, string> = {
		["cel"]: "Célibataire",
		["div"]: "Divorcé(e)",
		["mpc"]: "Mère ou père célibataire",
		["vma"]: "Vie maritale",
		["mar"]: "Marié(e)",
		["sep"]: "Séparé(e)",
		["veu"]: "Veuf(ve)",
		["inc"]: "Inconnue",
		["pac"]: "Pacsé(e)",
		["ccb"]: "Concubin(e)",
		["dcd"]: "Décédé(e)",
	};
}
