export interface PaymentNotice {
	addedDate: Date;
	displayName: string;
	id: number;
	name: string;
	title: string;
	typeCode: PaymentNoticeType;
}

export enum PaymentNoticeType {
	ChargesStatement = 0,
	PaymentNotice = 1,
}
