import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import {
	AccountStatementResponse,
	DirectDebitResponse,
	OnlinePaymentInfoResponse,
	OnlinePaymentParametersRequest,
	OnlinePaymentParametersResponse,
	PaymentNotice,
	PaymentResponse,
} from "../types";

@Injectable({
	providedIn: "root",
})
export class BalancesService {
	private readonly httpClient = inject(RentalsHttpClient);

	private lastPaymentsSubject$ = new BehaviorSubject<string>("Init");
	onLastPaymentsChange$: Observable<string> = this.lastPaymentsSubject$.asObservable();

	getOnlinePaymentInfoByRental(rentalId: string): Observable<OnlinePaymentInfoResponse> {
		return this.httpClient.get<OnlinePaymentInfoResponse>(`/api/rental-balances/${rentalId}/balance-info`);
	}

	getLastPaymentsByRental(rentalId: string): Observable<PaymentResponse[]> {
		return this.httpClient.get<PaymentResponse[]>(`/api/rental-balances/${rentalId}/last-payments`);
	}

	getLastPaymentNoticeByRental(rentalId: string): Observable<PaymentNotice> {
		return this.httpClient.get<PaymentNotice>(`/api/rental-balances/${rentalId}/last-payment-notice`);
	}

	getPaymentNoticeHistoryByRental(rentalId: string): Observable<PaymentNotice[]> {
		return this.httpClient.get<PaymentNotice[]>(`/api/rental-balances/${rentalId}/payment-notice-history`);
	}

	getAccountStatementByRental(rentalId: string): Observable<AccountStatementResponse> {
		return this.httpClient.get<AccountStatementResponse>(`/api/rental-balances/${rentalId}/account-statement`);
	}

	getDirectDebitByRental(rentalId: string, thirdPartyId: string): Observable<DirectDebitResponse> {
		return this.httpClient.get<DirectDebitResponse>(
			`/api/rental-balances/${thirdPartyId}/${rentalId}/direct-debit-info`
		);
	}

	retrieveOnlinePaymentParameters(
		thirdPartyId: string,
		rentalId: string,
		request: OnlinePaymentParametersRequest
	): Observable<OnlinePaymentParametersResponse> {
		return this.httpClient.post<OnlinePaymentParametersResponse>(
			`/api/rental-balances/${thirdPartyId}/${rentalId}/payment-form-link`,
			request
		);
	}

	reloadLastPayments(): void {
		this.lastPaymentsSubject$.next("Reload");
	}
}
