import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import { UpdateEmailRequest, UpdatePasswordRequest } from "../types";

@Injectable({
	providedIn: "root",
})
export class UsersService {
	private readonly httpClient = inject(RentalsHttpClient);

	updateEmail(username: string, request: UpdateEmailRequest): Observable<void> {
		return this.httpClient.post<void>(`/api/users/${username}/change-email`, request);
	}

	updatePassword(username: string, request: UpdatePasswordRequest): Observable<void> {
		return this.httpClient.post<void>(`/api/users/${username}/change-password`, request);
	}
}
