import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import { FormResponse, QuestionAnswersRequest } from "../types";

@Injectable({
	providedIn: "root",
})
export class FormsService {
	private readonly httpClient = inject(RentalsHttpClient);

	getLastActive(thirdPartyId: string): Observable<FormResponse> {
		return this.httpClient.get<FormResponse>(`/api/forms/third-parties/${thirdPartyId}`);
	}

	registerQuestionAnswers(thirdPartyId: string, request: QuestionAnswersRequest): Observable<any> {
		return this.httpClient.post<any>(`/api/forms/third-parties/${thirdPartyId}/answers`, request);
	}
}
