import { Pipe, PipeTransform } from "@angular/core";
import { PreciseDate } from "@e-tenant-hub/shared/utils";
import { SolicitationFilter, SolicitationResponseItem } from "../types";

@Pipe({
	name: "filterSolicitation",
	pure: false,
})
export class FilterSolicitationPipe implements PipeTransform {
	private tmpValues = 0;

	public transform(values: SolicitationResponseItem[], filters?: SolicitationFilter[]): SolicitationResponseItem[] {
		if (!values) {
			return values;
		}

		if (!filters) {
			return values;
		}

		filters
			.filter((f) => f.selected && f.value)
			.forEach((filter) => {
				values = this.filter(values, filter);
				this.tmpValues++;
			});

		return values;
	}

	/**
	 * Return all solicitation binding with filter value
	 */
	private filter(values: SolicitationResponseItem[], filter: SolicitationFilter): SolicitationResponseItem[] {
		switch (filter.category) {
			case "dates": {
				if (Array.isArray(filter.value)) {
					const minDateFilter = filter.value[0];
					const maxDateFilter = filter.value[1];
					return values.filter(
						(v) =>
							v.creationDate &&
							new PreciseDate(v.creationDate.toString()) >= minDateFilter &&
							new PreciseDate(v.creationDate.toString()) <= maxDateFilter
					);
				}
				return values;
			}
			case "scope": {
				const isPublicFilter = filter.value === "public-scope";
				return values.filter((v) => v.isPublic === isPublicFilter);
			}
			case "state": {
				return values.filter((v) => v.status === filter.value);
			}
			case "category": {
				return values.filter((v) => v.category === filter.value);
			}
			case "search": {
				return values.filter((v) => {
					if (v.title) return v.title?.toLowerCase().includes(String(filter.value).toLowerCase());
					return v.title.toLowerCase().includes(String(filter.value).toLowerCase());
				});
			}
			default: {
				return values;
			}
		}
	}
}
