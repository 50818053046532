export class SolicitationStatus {
	static readonly canceled: string = "canceled";
	static readonly closed: string = "closed";
	static readonly inProgress: string = "in-progress";
	static readonly registered: string = "registered";
	static readonly requested: string = "requested";
	static readonly unknown: string = "unknown";

	static readonly closedCategoryItems: string[] = [SolicitationStatus.canceled, SolicitationStatus.closed];

	static readonly inProgressCategoryItems: string[] = [
		SolicitationStatus.inProgress,
		SolicitationStatus.registered,
		SolicitationStatus.requested,
	];

	static readonly record: Record<string, string> = {
		[SolicitationStatus.canceled]: "Annulée",
		[SolicitationStatus.closed]: "Clôturée",
		[SolicitationStatus.inProgress]: "En cours",
		[SolicitationStatus.registered]: "Enregistrée",
		[SolicitationStatus.requested]: "Demandée",
		[SolicitationStatus.unknown]: "Inconnue",
	};
}

export class SolicitationCategories {
	static readonly customerRelationshipCenter: string = "CRC";
	static readonly disorders: string = "TRO";
	static readonly rentalManagement: string = "GL";
	static readonly technical: string = "TEC";

	// Warning: Order is important !
	static readonly all: string[] = [
		SolicitationCategories.rentalManagement,
		SolicitationCategories.technical,
		SolicitationCategories.disorders,
	];

	static readonly record: Record<string, string> = {
		[SolicitationCategories.customerRelationshipCenter]: "Centre relation clients",
		[SolicitationCategories.disorders]: "Incivilités et troubles de voisinage",
		[SolicitationCategories.rentalManagement]: "Gestion Locative",
		[SolicitationCategories.technical]: "Gestion Technique",
	};

	static readonly shortRecord: Record<string, string> = {
		[SolicitationCategories.customerRelationshipCenter]: "Relation clients",
		[SolicitationCategories.disorders]: "Troubles",
		[SolicitationCategories.rentalManagement]: "Administrative",
		[SolicitationCategories.technical]: "Technique",
	};
}

export class SolicitationSubCategories {
	static readonly bulkyItemsClearing: string = "bulkyItemsClearing";
	static readonly cleaningServices: string = "cleaningServices";
	static readonly documentsRequest: string = "documentsRequest";
	static readonly disorders: string = "disorders";
	static readonly electricity: string = "electricity";
	static readonly familySituationChange: string = "familySituationChange";
	static readonly floors: string = "floors";
	static readonly garageRequest: string = "garageRequest";
	static readonly glazing: string = "glazing";
	static readonly heatingAndVentilation: string = "heatingAndVentilation";
	static readonly homeAdaptationRequest: string = "homeAdaptationRequest";
	static readonly intercom: string = "intercom";
	static readonly joinery: string = "joinery";
	static readonly lift: string = "lift";
	static readonly locksmith: string = "locksmith";
	static readonly masonry: string = "masonry";
	static readonly others: string = "others";
	static readonly outdoorAreas: string = "outdoorAreas";
	static readonly paymentTermsAndConditions: string = "paymentTermsAndConditions";
	static readonly pests: string = "pests";
	static readonly plumbing: string = "plumbing";
	static readonly rentAndCosts: string = "rentAndCosts";
	static readonly residentialAutomaticDoor: string = "residentialAutomaticDoor";
	static readonly safetyEquipment: string = "safetyEquipment";
	static readonly securityDepositRefund: string = "securityDepositRefund";
	static readonly seepageAndTightness: string = "seepageAndTightness";
	static readonly slsOpsSurvey: string = "slsOpsSurvey";
	static readonly television: string = "television";
	static readonly workAuthorizationRequest: string = "workAuthorizationRequest";

	static readonly record: Record<string, string> = {
		[SolicitationSubCategories.bulkyItemsClearing]: "Débarrassage d'encombrants",
		[SolicitationSubCategories.cleaningServices]: "Prestation de nettoyage",
		[SolicitationSubCategories.documentsRequest]: "Demande de documents",
		[SolicitationSubCategories.disorders]: "Incivilités et troubles de voisinage",
		[SolicitationSubCategories.electricity]: "Électricité",
		[SolicitationSubCategories.familySituationChange]: "Changement situation familiale",
		[SolicitationSubCategories.floors]: "Sols",
		[SolicitationSubCategories.garageRequest]: "Demande de garage",
		[SolicitationSubCategories.glazing]: "Vitrerie",
		[SolicitationSubCategories.heatingAndVentilation]: "Chauffage/Chauffe-bain/Ventilation",
		[SolicitationSubCategories.homeAdaptationRequest]: "Demande adaptation logement",
		[SolicitationSubCategories.intercom]: "Interphonie",
		[SolicitationSubCategories.joinery]: "Menuiserie",
		[SolicitationSubCategories.lift]: "Ascenseur",
		[SolicitationSubCategories.locksmith]: "Serrurerie",
		[SolicitationSubCategories.masonry]: "Maçonnerie",
		[SolicitationSubCategories.others]: "Autres",
		[SolicitationSubCategories.paymentTermsAndConditions]: "Modalités de règlement",
		[SolicitationSubCategories.outdoorAreas]: "Espaces extérieurs",
		[SolicitationSubCategories.pests]: "Nuisibles",
		[SolicitationSubCategories.plumbing]: "Plomberie",
		[SolicitationSubCategories.rentAndCosts]: "Loyer/Charges",
		[SolicitationSubCategories.residentialAutomaticDoor]: "Porte automatique résidence",
		[SolicitationSubCategories.safetyEquipment]: "Équipement de sécurité",
		[SolicitationSubCategories.securityDepositRefund]: "Remboursement dépôt de garantie",
		[SolicitationSubCategories.seepageAndTightness]: "Infiltration/Étanchéité",
		[SolicitationSubCategories.slsOpsSurvey]: "Enquêtes SLS/OPS",
		[SolicitationSubCategories.television]: "Télévision",
		[SolicitationSubCategories.workAuthorizationRequest]: "Demande autorisation travaux",
	};

	// Warning: Order is important !
	static readonly allDisorders: string[] = [SolicitationSubCategories.disorders];

	// Warning: Order is important !
	static readonly allRentalManagement: string[] = [
		SolicitationSubCategories.familySituationChange,
		SolicitationSubCategories.securityDepositRefund,
		SolicitationSubCategories.slsOpsSurvey,
		SolicitationSubCategories.rentAndCosts,
		SolicitationSubCategories.garageRequest,
		SolicitationSubCategories.paymentTermsAndConditions,
		SolicitationSubCategories.documentsRequest,
	];

	// Warning: Order is important !
	static readonly allTechnical: string[] = [
		SolicitationSubCategories.cleaningServices,
		SolicitationSubCategories.plumbing,
		SolicitationSubCategories.lift,
		SolicitationSubCategories.others,
		SolicitationSubCategories.heatingAndVentilation,
		SolicitationSubCategories.electricity,
		SolicitationSubCategories.bulkyItemsClearing,
		SolicitationSubCategories.outdoorAreas,
		SolicitationSubCategories.pests,
		SolicitationSubCategories.seepageAndTightness,
		SolicitationSubCategories.intercom,
		SolicitationSubCategories.masonry,
		SolicitationSubCategories.joinery,
		SolicitationSubCategories.residentialAutomaticDoor,
		SolicitationSubCategories.safetyEquipment,
		SolicitationSubCategories.locksmith,
		SolicitationSubCategories.floors,
		SolicitationSubCategories.television,
		SolicitationSubCategories.glazing,
	];
}

export class SolicitationScopes {
	static readonly private: string = "private";
	static readonly public: string = "public";

	// Warning: Order is important !
	static readonly all: string[] = [SolicitationScopes.private, SolicitationScopes.public];

	static readonly record: Record<string, string> = {
		[SolicitationScopes.private]: "mon logement",
		[SolicitationScopes.public]: "la résidence",
	};

	static readonly partRecord: Record<string, string> = {
		[SolicitationScopes.private]: "Partie Privative",
		[SolicitationScopes.public]: "Partie Commune",
	};
}

export class SolicitationCommentTypes {
	static readonly closureRequest: string = "TRTAFF";
	static readonly informationRequest: string = "APELLO";
	static readonly reminder: string = "RELEXE";

	static readonly tagRecord: Record<string, string> = {
		[SolicitationCommentTypes.closureRequest]: "Demander la clôture",
		[SolicitationCommentTypes.reminder]: "Relancer la demande",
	};

	static readonly iconRecord: Record<string, string> = {
		[SolicitationCommentTypes.closureRequest]: "check_circle",
		[SolicitationCommentTypes.reminder]: "notifications",
	};

	static readonly formRecord: Record<string, string> = {
		[SolicitationCommentTypes.closureRequest]: "Un locataire a demandé la clôture de la demande.",
		[SolicitationCommentTypes.reminder]: "Un locataire a relancé la demande.",
	};
}
