import { Injectable, inject } from "@angular/core";
import { Colors } from "@e-tenant-hub/shared/utils";
import { BehaviorSubject, Observable, map, tap } from "rxjs";
import { SolicitationStatus } from "../constants";
import { RentalsHttpClient } from "../http-clients";
import {
	GetAllSolicitationsResponse,
	NbNewSolicitationsResponseItem,
	SolicitationCreationRequest,
	SolicitationCreationResponse,
	SolicitationResponseItem,
	SolicitationStepRequest,
} from "../types";

@Injectable({
	providedIn: "root",
})
export class SolicitationsService {
	private readonly httpClient = inject(RentalsHttpClient);

	private solicitationUpdateSubject$ = new BehaviorSubject<string>("Init");
	onSolicitationUpdate$: Observable<string> = this.solicitationUpdateSubject$.asObservable();

	private nbReadSolicitationsSubject$ = new BehaviorSubject<string>("Init");
	onNbReadSolicitationsChange$ = this.nbReadSolicitationsSubject$.asObservable();

	private isNewSolicitationRequestDisabledSubject$ = new BehaviorSubject<boolean>(true);
	isNewSolicitationRequestDisabled$: Observable<boolean> =
		this.isNewSolicitationRequestDisabledSubject$.asObservable();

	getNbNewSolicitationsByRental(rentalId: string): Observable<number> {
		const response = this.httpClient.get<NbNewSolicitationsResponseItem>(
			`/api/solicitations/new/rentals/${rentalId}`
		);
		return response.pipe(map((r) => r.nbNewSolicitations));
	}

	getAllSolicitationsByRental(rentalId: string): Observable<GetAllSolicitationsResponse> {
		return this.httpClient
			.get<GetAllSolicitationsResponse>(`/api/solicitations/rentals/${rentalId}`)
			.pipe(
				tap((response: GetAllSolicitationsResponse) =>
					this.isNewSolicitationRequestDisabledSubject$.next(response.isNewSolicitationRequestDisabled)
				)
			);
	}

	createSolicitation(request: SolicitationCreationRequest): Observable<SolicitationCreationResponse> {
		return this.httpClient.post(`/api/solicitations`, request);
	}

	addSolicitationStep(
		solicitationId: string,
		request: SolicitationStepRequest
	): Observable<SolicitationResponseItem> {
		return this.httpClient.post(`/api/solicitations/${solicitationId}/steps`, request);
	}

	markSolicitationAsRead(solicitationId: string): Observable<void> {
		return this.httpClient.put(`/api/solicitations/${solicitationId}/read`, null);
	}

	reloadSolicitations(): void {
		this.solicitationUpdateSubject$.next("Reload");
	}

	reloadNbReadSolicitations(): void {
		this.nbReadSolicitationsSubject$.next("Reload");
	}

	determineSolicitationStatusColor(status: string): Colors {
		switch (status) {
			case SolicitationStatus.canceled:
				return Colors.Error;
			case SolicitationStatus.closed:
				return Colors.Success;
			case SolicitationStatus.requested:
			case SolicitationStatus.unknown:
				return Colors.Neutral400;
			default:
				return Colors.Primary;
		}
	}
}
