import { Directive } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
	standalone: true,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: NoopValueAccessorDirective,
		},
	],
})
export class NoopValueAccessorDirective implements ControlValueAccessor {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	writeValue(obj: any): void {}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	registerOnChange(fn: any): void {}
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	registerOnTouched(fn: any): void {}
}
