export class RentalContactPersonRoles {
	static readonly buildingManager: string = "ri";
	static readonly areaManager: string = "rs";
	static readonly socialAndRentalManager: string = "gls";
	static readonly mediationAndResidentialPeaceOfficer: string = "cpv";
	static readonly territoryAssistant: string = "adj";
	static readonly territoryManager: string = "dag";
	static readonly specialPremisesManager: string = "ctc";

	static readonly record: Record<string, string> = {
		[RentalContactPersonRoles.buildingManager]: "Responsable d'immeuble",
		[RentalContactPersonRoles.areaManager]: "Responsable de secteur",
		[RentalContactPersonRoles.socialAndRentalManager]: "Gestionnaire locative et sociale",
		[RentalContactPersonRoles.mediationAndResidentialPeaceOfficer]: "Chargé de tranquillité sûreté",
		[RentalContactPersonRoles.territoryAssistant]: "Adjoint de territoire",
		[RentalContactPersonRoles.territoryManager]: "Directeur de territoire",
		[RentalContactPersonRoles.specialPremisesManager]: "Chargée de gestion des locaux spécifiques",
	};
}

export class RentalAccommodationTypes {
	static readonly buildingJanitor: string = "lgd";
	static readonly housingBRS: string = "br1";
	static readonly garage: string = "gar";
	static readonly residence: string = "foy";
	static readonly office: string = "bur";
	static readonly land: string = "ter";
	static readonly bikeGarage: string = "gvl";
	static readonly technicalReserve: string = "tec";
	static readonly professionalPremises: string = "lpr";
	static readonly residentCollectivePremises: string = "lcr";
	static readonly garageOrParkBRS: string = "br2";
	static readonly park: string = "par";
	static readonly gargabeRoom: string = "lpc";
	static readonly maintenanceRoom: string = "ent";
	static readonly motoRoom: string = "l2r";
	static readonly studentResidence: string = "fo1";
	static readonly miscellaneousRentals: string = "ldi";
	static readonly laundry: string = "lav";
	static readonly cellar: string = "cav";
	static readonly business: string = "com";
	static readonly studentAccommodation: string = "etu";
	static readonly cellarBRS: string = "br3";
	static readonly housing: string = "hab";
	static readonly nonPropertyPremises: string = "lhp";
	static readonly motoBoxing: string = "bxm";

	static readonly record: Record<string, string> = {
		[RentalAccommodationTypes.buildingJanitor]: "Local gardien",
		[RentalAccommodationTypes.housingBRS]: "Habitation/BRS",
		[RentalAccommodationTypes.garage]: "Garage",
		[RentalAccommodationTypes.residence]: "Foyer",
		[RentalAccommodationTypes.office]: "Bureau",
		[RentalAccommodationTypes.land]: "Terrain",
		[RentalAccommodationTypes.bikeGarage]: "Garage à vélo",
		[RentalAccommodationTypes.technicalReserve]: "Réserve technique",
		[RentalAccommodationTypes.professionalPremises]: "Local professionnel",
		[RentalAccommodationTypes.residentCollectivePremises]: "Local collectif résident",
		[RentalAccommodationTypes.garageOrParkBRS]: "Garage ou Parking/BRS",
		[RentalAccommodationTypes.park]: "Parking",
		[RentalAccommodationTypes.gargabeRoom]: "Local poubelle container",
		[RentalAccommodationTypes.maintenanceRoom]: "Local entretien",
		[RentalAccommodationTypes.motoRoom]: "Local 2 roues",
		[RentalAccommodationTypes.studentResidence]: "Foyer étudiant",
		[RentalAccommodationTypes.miscellaneousRentals]: "Location diverses",
		[RentalAccommodationTypes.laundry]: "Laverie",
		[RentalAccommodationTypes.cellar]: "Cave",
		[RentalAccommodationTypes.business]: "Commerce",
		[RentalAccommodationTypes.studentAccommodation]: "Logement étudiant",
		[RentalAccommodationTypes.cellarBRS]: "Cave/BRS",
		[RentalAccommodationTypes.housing]: "Habitation",
		[RentalAccommodationTypes.nonPropertyPremises]: "Locaux hors patrimoine",
		[RentalAccommodationTypes.motoBoxing]: "Boxe moto",
	};

	static readonly isPrimaryRecord: Record<string, boolean> = {
		[RentalAccommodationTypes.buildingJanitor]: true,
		[RentalAccommodationTypes.housingBRS]: true,
		[RentalAccommodationTypes.garage]: false,
		[RentalAccommodationTypes.residence]: true,
		[RentalAccommodationTypes.office]: true,
		[RentalAccommodationTypes.land]: true,
		[RentalAccommodationTypes.bikeGarage]: false,
		[RentalAccommodationTypes.technicalReserve]: false,
		[RentalAccommodationTypes.professionalPremises]: true,
		[RentalAccommodationTypes.residentCollectivePremises]: false,
		[RentalAccommodationTypes.garageOrParkBRS]: false,
		[RentalAccommodationTypes.park]: false,
		[RentalAccommodationTypes.gargabeRoom]: false,
		[RentalAccommodationTypes.maintenanceRoom]: false,
		[RentalAccommodationTypes.motoRoom]: false,
		[RentalAccommodationTypes.studentResidence]: true,
		[RentalAccommodationTypes.miscellaneousRentals]: false,
		[RentalAccommodationTypes.laundry]: false,
		[RentalAccommodationTypes.cellar]: false,
		[RentalAccommodationTypes.business]: true,
		[RentalAccommodationTypes.studentAccommodation]: true,
		[RentalAccommodationTypes.cellarBRS]: false,
		[RentalAccommodationTypes.housing]: true,
		[RentalAccommodationTypes.nonPropertyPremises]: true,
		[RentalAccommodationTypes.motoBoxing]: false,
	};
}

export class RentalHabitationTypes {
	static readonly type1: string = "t1";
	static readonly type1bis: string = "t1b";
	static readonly type2: string = "t2";
	static readonly type3: string = "t3";
	static readonly type4: string = "t4";
	static readonly type5: string = "t5";
	static readonly type6: string = "t6";
	static readonly type7: string = "t7";
	static readonly garage: string = "gar";
	static readonly professionalPremises: string = "lp";
	static readonly business: string = "com";
	static readonly office: string = "bur";
	static readonly residentCollectivePremises: string = "lcr";
	static readonly studio: string = "stu";
	static readonly miscellaneousRentals: string = "ld";
	static readonly land: string = "ter";
	static readonly residence: string = "fo";
	static readonly room: string = "cha";
	static readonly restructuredType3: string = "t3r";
	static readonly restructuredType4: string = "t4r";
	static readonly restructuredType1: string = "t1r";
	static readonly restructuredType2: string = "t2r";
	static readonly restructuredType5: string = "t5r";
	static readonly restructuredType6: string = "t6r";
	static readonly slotsLocation: string = "loe";
	static readonly type3bis: string = "t3b";
	static readonly type4bis: string = "t4b";
	static readonly parking: string = "par";

	static readonly record: Record<string, string> = {
		[RentalHabitationTypes.type1]: "Type 1",
		[RentalHabitationTypes.type1bis]: "Type 1 bis",
		[RentalHabitationTypes.type2]: "Type 2",
		[RentalHabitationTypes.type3]: "Type 3",
		[RentalHabitationTypes.type4]: "Type 4",
		[RentalHabitationTypes.type5]: "Type 5",
		[RentalHabitationTypes.type6]: "Type 6",
		[RentalHabitationTypes.type7]: "Type 7",
		[RentalHabitationTypes.garage]: "Garage",
		[RentalHabitationTypes.professionalPremises]: "Local professsionnel",
		[RentalHabitationTypes.business]: "Commerce",
		[RentalHabitationTypes.office]: "Bureau",
		[RentalHabitationTypes.residentCollectivePremises]: "Local collectif résident",
		[RentalHabitationTypes.studio]: "Studio",
		[RentalHabitationTypes.miscellaneousRentals]: "Location Diverse",
		[RentalHabitationTypes.land]: "Terrain",
		[RentalHabitationTypes.residence]: "Foyer",
		[RentalHabitationTypes.room]: "Chambre",
		[RentalHabitationTypes.restructuredType3]: "Type 3 restucturé",
		[RentalHabitationTypes.restructuredType4]: "Type 4 restucturé",
		[RentalHabitationTypes.restructuredType1]: "Type 1 restucturé",
		[RentalHabitationTypes.restructuredType2]: "Type 2 restucturé",
		[RentalHabitationTypes.restructuredType5]: "Type 5 restucturé",
		[RentalHabitationTypes.restructuredType6]: "Type 6 restucturé",
		[RentalHabitationTypes.slotsLocation]: "Location emplacements",
		[RentalHabitationTypes.type3bis]: "Type 3 bis",
		[RentalHabitationTypes.type4bis]: "Type 4 bis",
		[RentalHabitationTypes.parking]: "Parking",
	};
}

export class RentalExpertiseAreaCodes {
	static readonly elevator: string = "ka2";
	static readonly playgrounds: string = "kaj";
	static readonly tv: string = "kca";
	static readonly individualBoiler: string = "kcv";
	static readonly individualBoiler2: string = "kci";
	static readonly gasWaterHeater: string = "kcb";
	static readonly cumulus: string = "kcm";
	static readonly collectiveHeating: string = "kc2";
	static readonly collectiveHeating2: string = "ku1";
	static readonly collectiveHeating3: string = "kc1";
	static readonly waterMeter: string = "kce";
	static readonly insectControl: string = "kde";
	static readonly multiMaintenance: string = "kme";
	static readonly automatedGarageDoor: string = "kpg";
	static readonly vmc: string = "kvm";

	static readonly record: Record<string, string> = {
		[RentalExpertiseAreaCodes.elevator]: "Ascenseur",
		[RentalExpertiseAreaCodes.playgrounds]: "Aires de jeux",
		[RentalExpertiseAreaCodes.tv]: "Télévision",
		[RentalExpertiseAreaCodes.individualBoiler]: "Chaudière individuelle",
		[RentalExpertiseAreaCodes.individualBoiler2]: "Chaudière individuelle",
		[RentalExpertiseAreaCodes.gasWaterHeater]: "Chauffe-eau gaz",
		[RentalExpertiseAreaCodes.cumulus]: "Cumulus",
		[RentalExpertiseAreaCodes.collectiveHeating]: "Chauffage collectif",
		[RentalExpertiseAreaCodes.collectiveHeating2]: "Chauffage collectif",
		[RentalExpertiseAreaCodes.collectiveHeating3]: "Chauffage collectif",
		[RentalExpertiseAreaCodes.waterMeter]: "Compteur d'eau",
		[RentalExpertiseAreaCodes.insectControl]: "Désinsectisation",
		[RentalExpertiseAreaCodes.multiMaintenance]: "Multi-entretien",
		[RentalExpertiseAreaCodes.automatedGarageDoor]: "Porte automatisée garage",
		[RentalExpertiseAreaCodes.vmc]: "VMC",
	};
}

export class RentalWaterTypes {
	static readonly hotWater: string = "hot-water";
	static readonly coldWater: string = "cold-water";

	static readonly record: Record<string, string> = {
		[RentalWaterTypes.hotWater]: "Eau chaude",
		[RentalWaterTypes.coldWater]: "Eau froide",
	};
}

export class RentalLotTypes {
	static readonly accommodation: string = "log";
	static readonly studentAccommodation: string = "etu";
	static readonly cellar: string = "cav";
	static readonly garage: string = "gar";

	static readonly record: Record<string, string> = {
		[RentalLotTypes.accommodation]: "Logement",
		[RentalLotTypes.studentAccommodation]: "Logement étudiant",
		[RentalLotTypes.cellar]: "Cave",
		[RentalLotTypes.garage]: "Garage",
	};
}
