import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import {
	BaseApiResponse,
	ThirdParty,
	ThirdPartyPreference,
	ThirdPartyPreferencesRequest,
	ThirdPartySuggestionResponse,
	ThirdPartySurveySetting,
} from "../types";

@Injectable({
	providedIn: "root",
})
export class ThirdPartiesService {
	private readonly httpClient = inject(RentalsHttpClient);

	getContactDetail(rentalId: string, thirdPartyId: string): Observable<ThirdParty> {
		return this.httpClient.get<ThirdParty>(`/api/third-parties/${thirdPartyId}/${rentalId}/contact-detail`);
	}

	getPreferences(thirdPartyId: string): Observable<ThirdPartyPreference[]> {
		return this.httpClient.get<ThirdPartyPreference[]>(`/api/third-parties/${thirdPartyId}/preferences`);
	}

	getSurveySettings(): Observable<ThirdPartySurveySetting[]> {
		return this.httpClient.get<ThirdPartySurveySetting[]>(`/api/third-parties/survey-settings`);
	}

	getThirdPartiesByRental(tenantNumber: string): Observable<ThirdPartySuggestionResponse[]> {
		return this.httpClient.get<ThirdPartySuggestionResponse[]>(`/api/third-parties/rental/${tenantNumber}`);
	}

	getThirdPartiesByFamilyName(familyName: string): Observable<ThirdPartySuggestionResponse[]> {
		return this.httpClient.get<ThirdPartySuggestionResponse[]>(`/api/third-parties/${familyName}`);
	}

	updateContactDetail(rentalId: string, thirdPartyId: string, request: ThirdParty): Observable<void> {
		return this.httpClient.post<void>(`/api/third-parties/${thirdPartyId}/${rentalId}/contact-detail`, request);
	}

	updatePreferences(thirdPartyId: string, request: ThirdPartyPreferencesRequest): Observable<BaseApiResponse> {
		return this.httpClient.post<BaseApiResponse>(`/api/third-parties/${thirdPartyId}/preferences`, request);
	}
}
