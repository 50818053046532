export enum PreferenceType {
	noticeDelivery = "notice-delivery",
	favoriteContactMode = "favorite-contact-mode",
}

export class NotificationType {
	static readonly email = "email";
	static readonly post = "post";
	static readonly tenantSpace = "tenant-space";
	static readonly sms = "sms";
}
