import { PreciseDate } from "@e-tenant-hub/shared/utils";

export interface FormResponse {
	id: string;
	isMandatory: boolean;
	startDate: PreciseDate;
	endDate: PreciseDate;
	description: string;
	title: string;
	validationMessage: string;
	questions: QuestionResponse[];
}

export interface QuestionResponse {
	id: string;
	description: string;
	order: number;
	propositions: PropositionReponse[];
	title: string;
	type: string;
}

export interface PropositionReponse {
	value: string;
}

export enum QuestionType {
	SingleChoice = "single",
	MultipleChoice = "multiple",
	FreeAnswer = "free",
}

export interface QuestionAnswersRequest {
	answers: QuestionAnswer[];
}

export interface QuestionAnswer {
	questionId: string;
	answer: string;
}
