import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class CommunicationService {
	private _insuranceUploadedSubject$ = new BehaviorSubject<string>("init");
	private _rentalSelectionChangedSubject$ = new BehaviorSubject<string>("init");

	onInsuranceUploaded$: Observable<string> = this._insuranceUploadedSubject$.asObservable();
	onRentalSelectionChanged$: Observable<string> = this._rentalSelectionChangedSubject$.asObservable();

	changeRentalUnitSelection(): void {
		this._rentalSelectionChangedSubject$.next("reload");
	}

	insuranceUploaded(): void {
		this._insuranceUploadedSubject$.next("uploaded");
	}
}
