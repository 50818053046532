import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { RentalsHttpClient } from "../http-clients";
import {
	AccommodationResponse,
	AgencyResponse,
	ConsumptionResponse,
	ContactPersonResponse,
	ContractingCompanyResponse,
	RentalUnitResponse,
	RentalUnitsRequest,
} from "../types";

@Injectable({
	providedIn: "root",
})
export class RentalsService {
	private readonly httpClient = inject(RentalsHttpClient);

	getRentalById(rentalId: string): Observable<RentalUnitResponse> {
		return this.httpClient.get<RentalUnitResponse>(`/api/rentals/${rentalId}`);
	}

	getRentalsByIds(rentalIds: string[]): Observable<RentalUnitResponse[]> {
		return this.httpClient.post<RentalUnitResponse[]>(`/api/rentals/rentals-by-ids`, {
			rentalIds: rentalIds,
		} as RentalUnitsRequest);
	}

	getRentalsByUsername(userName: string): Observable<RentalUnitResponse[]> {
		return this.httpClient.post<RentalUnitResponse[]>(`/api/rentals/rentals-by-username`, { userName: userName });
	}

	getRentalContactPersons(rentalId: string, isLegalPerson: boolean): Observable<ContactPersonResponse[]> {
		if (isLegalPerson) {
			return this.httpClient.get<ContactPersonResponse[]>(`/api/rentals/${rentalId}/legal-contact-persons`);
		}

		return this.httpClient.get<ContactPersonResponse[]>(`/api/rentals/${rentalId}/contact-persons`);
	}

	getRentalAgency(rentalId: string): Observable<AgencyResponse> {
		return this.httpClient.get<AgencyResponse>(`/api/rentals/${rentalId}/agency`);
	}

	getAccommodationsByRental(rentalId: string): Observable<AccommodationResponse[]> {
		return this.httpClient.get<AccommodationResponse[]>(`/api/rentals/${rentalId}/accommodations`);
	}

	getContractingCompaniesByRental(rentalId: string): Observable<ContractingCompanyResponse[]> {
		return this.httpClient.get<ContractingCompanyResponse[]>(`/api/rentals/${rentalId}/contracting-companies`);
	}

	getConsumptionsByRental(rentalId: string): Observable<ConsumptionResponse[]> {
		return this.httpClient.get<ConsumptionResponse[]>(`/api/rentals/${rentalId}/consumptions`);
	}
}
