import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ConfigModule } from "@e-tenant-hub/shared/config";
import { UtilsModule } from "@e-tenant-hub/shared/utils";
import { RentalsHttpClient } from "./http-clients";
import { FilterSolicitationPipe } from "./pipes";

@NgModule({
	imports: [CommonModule, ConfigModule, UtilsModule],
	declarations: [FilterSolicitationPipe],
	exports: [FilterSolicitationPipe],
	providers: [
		{
			provide: RentalsHttpClient,
			deps: [HttpClient],
		},
	],
})
export class RentalsModule {}
