import { HttpEvent } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { FileProgress, RentalsHttpClient } from "../http-clients";
import { BaseApiResponse, InsuranceStatus, UpdateInsuranceRequest } from "../types";

@Injectable({
	providedIn: "root",
})
export class InsurancesService {
	private readonly httpClient = inject(RentalsHttpClient);

	private _uploadInsuranceFileSource = new Subject<FileProgress>();
	public onUploadInsuranceFile$: Observable<FileProgress> = this._uploadInsuranceFileSource.asObservable();

	getInsuranceStatusByRental(rentalId: string): Observable<InsuranceStatus> {
		return this.httpClient.get<InsuranceStatus>(`/api/rentals/${rentalId}/insurance`);
	}

	public updateInsuranceDocument(
		thirdPartyId: string,
		rentalId: string,
		request: UpdateInsuranceRequest
	): Observable<HttpEvent<BaseApiResponse>> {
		const formData = new FormData();
		formData.append("file", request.file, request.file.name);
		formData.append("beginDate", request.beginDate.toISOString());
		formData.append("endDate", request.endDate.toISOString());

		for (let i = 0; i < request.lotIdentifiers.length; i++)
			formData.append("lotIdentifiers[]", request.lotIdentifiers[i]);

		return this.httpClient.uploadFile<BaseApiResponse>(
			`/api/rentals/${thirdPartyId}/${rentalId}/insurance`,
			formData,
			(fileProgress: FileProgress) => this._uploadInsuranceFileSource.next(fileProgress)
		);
	}
}
