import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SolicitationFilter } from "../types";

@Injectable({
	providedIn: "root",
})
export class SolicitationsFilterService {
	private currentFilters!: SolicitationFilter[];
	private _filterChangedSubject = new BehaviorSubject<SolicitationFilter[]>([]);

	onFilterChanged$: Observable<SolicitationFilter[]> = this._filterChangedSubject.asObservable();

	constructor() {
		this.reset();
	}

	select(filter: SolicitationFilter): void {
		const currentFilterIndex: number = this.currentFilters.findIndex((cf) => cf.id === filter.id);

		// Filter by category except the current filter
		const sameCategoryFilters: SolicitationFilter[] = this.currentFilters.filter(
			(f) => f.category === filter.category && f.id !== filter.id
		);

		// Delete all the filter with the same category
		sameCategoryFilters.forEach((f: SolicitationFilter) => {
			const index: number = this.currentFilters.findIndex((cf) => cf.id === f.id);
			this.currentFilters.splice(index, 1);
		});

		// Add/update the new filter
		if (currentFilterIndex === -1) {
			this.currentFilters.push(filter);
		} else {
			this.currentFilters[currentFilterIndex].selected = !this.currentFilters[currentFilterIndex].selected;
		}

		this.handleNoFilter();

		// Notify
		this.notify();
	}

	remove(removedFilterId: string): void {
		if (!removedFilterId) {
			return;
		}

		const index = this.currentFilters.findIndex((f) => f.id === removedFilterId);

		if (index !== -1) {
			this.currentFilters.splice(index, 1);
		}

		this.handleNoFilter();
		this.notify();
	}

	getFilters(): SolicitationFilter[] {
		return this.currentFilters;
	}

	notify(): void {
		this._filterChangedSubject.next(this.currentFilters);
	}

	reset(): void {
		this.currentFilters = [];
		this.notify();
	}

	private handleNoFilter(): void {
		if (this.currentFilters.filter((cf) => cf.selected).length !== 0) {
			return;
		}

		this.reset();
	}
}
